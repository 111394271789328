import {
    Configuration,
    DataApi as DataRestApi,
    OperatorPrognosisMonetaryRepresentation,
    OperatorPrognosisMonetaryRequest,
    UploadCSVDataTypeEnum,
} from '@/clients/dashboardapi/v2';
import { IndexedDBRepository } from '@/modules/shared/indexeddb';
import type { AuthApi } from '@/modules/shared/adapter/AuthApi';
import type {
    ChartDataRepresentation,
    ChartDataRequest,
    DataApi,
    GaugeDataRepresentation,
    GaugeDataRequest,
    LatestLogDataRequest,
    LogDataRepresentation,
    LogDataRequest,
    PieDataRepresentation,
    PieDataRequest,
    PowercurveDataRepresentation,
    PowercurveDataRequest,
    ScatterDataRepresentation,
    ScatterDataRequest,
    WindroseDataRepresentation,
    WindroseDataRequest,
} from '@/modules/shared/adapter/DataApi';
import { AsyncDebouncer, AuthMiddleware, ConnectionResetMiddleware } from '../middleware';

interface DataCacheEntry {
    key: string;
    data: any;
}

export class CachedDataRestApi implements DataApi {
    private readonly auth: AuthApi;
    private readonly dataApi: DataRestApi;
    private readonly dataCache: IndexedDBRepository<string, DataCacheEntry>;

    constructor(indexedDb: Promise<IDBDatabase>, apis: { auth: AuthApi }) {
        const restApiConfig = new Configuration({
            accessToken: () => apis.auth.getAuthToken(),
            basePath: `${process.env.VUE_APP_SERVICE_API}v2`,
            credentials: 'include',
            middleware: [new AuthMiddleware(apis.auth), new ConnectionResetMiddleware()],
        });
        this.auth = apis.auth;
        this.dataApi = new DataRestApi(restApiConfig);
        this.dataCache = new IndexedDBRepository<string, DataCacheEntry>(indexedDb, 'data', {
            invalidateOnReload: true,
            invalidateOnUserChanged: false,
            invalidateOnInterval: CachedDataRestApi.maxCacheTime(),
        });
    }

    public async getTimeSeries(request: ChartDataRequest): Promise<ChartDataRepresentation[]> {
        const data = await this.fetchData(request, (r) => this.dataApi.getChartData(r));
        return data.map((value, index) => {
            const metricKey = request.resources[index].metricKey;
            // value is saved in ct/MWh, but labeled as ct/kWh
            // if (metricKey === 'halvar-eeg-mon') {
            //     return {
            //         ...value,
            //         y: value.y.map((it) => it / 1000 ),
            //     };
            // }
            return value;
        });
    }

    public async getTimeSeriesAsCSV(request: ChartDataRequest): Promise<Blob> {
        return this.dataApi.getChartDataAsCSV(request);
    }

    public async getNumeric(request: GaugeDataRequest): Promise<GaugeDataRepresentation> {
        return this.fetchData(request, (r) => this.dataApi.getGaugeData(r));
    }

    public async getDistribution(request: PieDataRequest): Promise<PieDataRepresentation> {
        return this.fetchData(request, (r) => this.dataApi.getPieData(r));
    }

    public async getWindrose(request: WindroseDataRequest): Promise<WindroseDataRepresentation> {
        return this.fetchData(request, (r) => this.dataApi.getWindroseData(r));
    }

    public async getScatter(request: ScatterDataRequest): Promise<ScatterDataRepresentation[]> {
        return this.fetchData(request, (r) => this.dataApi.getScatterData(r));
    }

    public async getPowerCurve(request: PowercurveDataRequest): Promise<PowercurveDataRepresentation[]> {
        return this.fetchData(request, (r) => this.dataApi.getPowercurveData(r));
    }

    public async getLatestLogs(request: LatestLogDataRequest): Promise<LogDataRepresentation[]> {
        return this.fetchData(request, (r) => this.dataApi.getLatestLogData(r));
    }

    public async getLogs(request: LogDataRequest): Promise<ReadableStream<Uint8Array>> {
        const response = await this.dataApi.getLogDataRaw({ logDataRequest: request });

        if (!response.raw.body) {
            throw new Error('ReadableStream not supported or no body in response');
        }

        return response.raw.body;
    }

    public async getLogsAsCSV(logDataRequest: LogDataRequest): Promise<Blob> {
        console.log('Generating CSV: [__________________________________________________] 0%');
        const url = `${process.env.VUE_APP_SERVICE_API}v2/data/csv/log`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': await this.auth.getAuthToken(),
            },
            body: JSON.stringify(logDataRequest),
        });

        const reader = response.body?.getReader();
        // this header is only accessible when frontend and backend are on the same domain!
        const generatorCount = parseInt(response.headers.get('X-Generator-Count') || '768', 10);
        const stream = new ReadableStream({
            async start(controller) {
                try {
                    const receivedGenerators: Set<string> = new Set<string>();
                    const decoder = new TextDecoder();
                    const encoder = new TextEncoder();
                    let lastPrintedProgress = 0;
                    let result = await reader?.read();
                    while (result && !result.done) {
                        let content = decoder
                            .decode(result.value)
                            // split content down to single csv cells
                            .split('\n')
                            .filter((line) => line.trim().length > 0)
                            .map((line) => line.split(';'))
                            .map((cells) => {
                                receivedGenerators.add(`${cells[0]} / ${cells[1]}`);
                                return cells;
                            })
                            // filter out dummy rows without data
                            .filter((cells) => cells[3] && cells[4])
                            .map((cells) => cells.join(';'))
                            .join('\n');
                        if (content.length > 0) {
                            // add newline after each block of log entries
                            content = `${content}\n`;
                        }
                        controller.enqueue(encoder.encode(content));
                        const progress = Math.floor((receivedGenerators.size / generatorCount) * 100);
                        if (progress > lastPrintedProgress) {
                            lastPrintedProgress = progress;
                            let out = 'Generating CSV: [';
                            for (let i = 1; i <= 100; i += 2) {
                                out = i < progress ? `${out}#` : `${out}_`;
                            }
                            out = `${out}] ${progress}% (${receivedGenerators.size}/${generatorCount})`;
                            console.log(out);
                        }

                        result = await reader?.read();
                    }
                    controller.close();
                } catch (e) {
                    controller.error(e);
                }
            },
        });
        return new Response(stream).blob();
    }

    public async uploadDataCSV(type: UploadCSVDataTypeEnum, blob: Blob): Promise<string> {
        return this.dataApi.uploadCSVData(type, blob);
    }

    public async getOperatorPrognosisMonetary(request: OperatorPrognosisMonetaryRequest): Promise<OperatorPrognosisMonetaryRepresentation> {
        return this.fetchData(request, (r) => this.dataApi.getOperatorPrognosisMonetary(request));
    }

    private fetchData<R, T>(request: R, cb: (request: R) => Promise<T>) {
        const hash = CachedDataRestApi.hashDataRequest(request);
        return AsyncDebouncer.debounce(`CachedDataRestApi.${hash}`, async () => {
            const cachedData = await this.dataCache.findByKey(hash);
            if (cachedData) {
                return cachedData.data as T;
            }
            const data = await cb(request);
            await this.dataCache.save({ key: hash, data: data });
            return data;
        });
    }

    private static hashDataRequest(object: any): string {
        const str = JSON.stringify(object);
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            const char = str.charCodeAt(i);
            // eslint-disable-next-line no-bitwise
            hash = ((hash << 5) - hash) + char;
            // eslint-disable-next-line no-bitwise
            hash &= hash; // Convert to 32bit integer
        }
        if (hash < 0) {
            return `1${(hash * -1).toString(16)}`;
        }
        return hash.toString(16);
    }

    private static maxCacheTime(): number {
        let seconds = 300;
        if (process.env.VUE_APP_MAX_DATA_CACHE) {
            try {
                seconds = parseInt(process.env.VUE_APP_MAX_DATA_CACHE, 10);
            } catch (e) {
                console.error(`VUE_APP_MAX_DATA_CACHE has an invalid value: ${process.env.VUE_APP_MAX_DATA_CACHE}. Using default of 300s.`);
            }
        }
        // cache at least 1 second
        return 1000 * Math.max(1, seconds);
    }
}

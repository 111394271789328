/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AssignCompanyRequest,
    AssignCompanyRequestFromJSON,
    AssignCompanyRequestToJSON,
    AssignedCompanyRepresentation,
    AssignedCompanyRepresentationFromJSON,
    AssignedCompanyRepresentationToJSON,
    DocumentRepresentation,
    DocumentRepresentationFromJSON,
    DocumentRepresentationToJSON,
} from '../models';

export interface AssignCompanyOperationRequest {
    docKey: string;
    assignCompanyRequest: AssignCompanyRequest;
}

export interface DeleteDocumentRequest {
    docKey: string;
}

export interface DeleteDocumentWarningRequest {
    docKey: string;
}

export interface DownloadDocumentRequest {
    docKey: string;
}

export interface DownloadDocumentsRequest {
    allDocuments?: Array<string>;
}

export interface GetAssignedCompanyKeysRequest {
    requestBody: Array<string>;
}

export interface GetDocumentRequest {
    docKey: string;
}

export interface GetDocumentsRequest {
    chunkSize?: number;
    chunkOffset?: number;
    text?: string;
    from?: number;
    until?: number;
    allGenerators?: Array<string>;
    anyGenerators?: Array<string>;
    allTags?: Array<string>;
    category?: string;
}

export interface RemoveCompanyRequest {
    docKey: string;
    companyKey: string;
}

export interface RetryRequest {
    docKey: string;
}

/**
 * 
 */
export class DocumentsApi extends runtime.BaseAPI {

    /**
     * Assign document to company
     * Assign document
     */
    async assignCompanyRaw(requestParameters: AssignCompanyOperationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.docKey === null || requestParameters.docKey === undefined) {
            throw new runtime.RequiredError('docKey','Required parameter requestParameters.docKey was null or undefined when calling assignCompany.');
        }

        if (requestParameters.assignCompanyRequest === null || requestParameters.assignCompanyRequest === undefined) {
            throw new runtime.RequiredError('assignCompanyRequest','Required parameter requestParameters.assignCompanyRequest was null or undefined when calling assignCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:read"]);
        }

        const response = await this.request({
            path: `/documents/by-id/{docKey}/assigned-companies`.replace(`{${"docKey"}}`, encodeURIComponent(String(requestParameters.docKey))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AssignCompanyRequestToJSON(requestParameters.assignCompanyRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Assign document to company
     * Assign document
     */
    async assignCompany(docKey: string, assignCompanyRequest: AssignCompanyRequest): Promise<void> {
        await this.assignCompanyRaw({ docKey: docKey, assignCompanyRequest: assignCompanyRequest });
    }

    /**
     * Delete a document, if owned by current user
     * Delete a document
     */
    async deleteDocumentRaw(requestParameters: DeleteDocumentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.docKey === null || requestParameters.docKey === undefined) {
            throw new runtime.RequiredError('docKey','Required parameter requestParameters.docKey was null or undefined when calling deleteDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:read"]);
        }

        const response = await this.request({
            path: `/documents/by-id/{docKey}`.replace(`{${"docKey"}}`, encodeURIComponent(String(requestParameters.docKey))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a document, if owned by current user
     * Delete a document
     */
    async deleteDocument(docKey: string): Promise<void> {
        await this.deleteDocumentRaw({ docKey: docKey });
    }

    /**
     * Delete a documents warning, if owned by current user
     * Delete a documents warning
     */
    async deleteDocumentWarningRaw(requestParameters: DeleteDocumentWarningRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.docKey === null || requestParameters.docKey === undefined) {
            throw new runtime.RequiredError('docKey','Required parameter requestParameters.docKey was null or undefined when calling deleteDocumentWarning.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:read"]);
        }

        const response = await this.request({
            path: `/documents/by-id/{docKey}/warning`.replace(`{${"docKey"}}`, encodeURIComponent(String(requestParameters.docKey))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a documents warning, if owned by current user
     * Delete a documents warning
     */
    async deleteDocumentWarning(docKey: string): Promise<void> {
        await this.deleteDocumentWarningRaw({ docKey: docKey });
    }

    /**
     * Get a documents content if accessible by the current user
     * Download a document
     */
    async downloadDocumentRaw(requestParameters: DownloadDocumentRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.docKey === null || requestParameters.docKey === undefined) {
            throw new runtime.RequiredError('docKey','Required parameter requestParameters.docKey was null or undefined when calling downloadDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:read"]);
        }

        const response = await this.request({
            path: `/documents/download/{docKey}`.replace(`{${"docKey"}}`, encodeURIComponent(String(requestParameters.docKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get a documents content if accessible by the current user
     * Download a document
     */
    async downloadDocument(docKey: string): Promise<Blob> {
        const response = await this.downloadDocumentRaw({ docKey: docKey });
        return await response.value();
    }

    /**
     * Download all accessible documents as zip
     * Get all documents
     */
    async downloadDocumentsRaw(requestParameters: DownloadDocumentsRequest): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters.allDocuments) {
            queryParameters['all_documents'] = requestParameters.allDocuments.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:read"]);
        }

        const response = await this.request({
            path: `/documents/download`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download all accessible documents as zip
     * Get all documents
     */
    async downloadDocuments(allDocuments?: Array<string>): Promise<Blob> {
        const response = await this.downloadDocumentsRaw({ allDocuments: allDocuments });
        return await response.value();
    }

    /**
     * Get assigned companies for the given document keys
     * Get assigned companies
     */
    async getAssignedCompanyKeysRaw(requestParameters: GetAssignedCompanyKeysRequest): Promise<runtime.ApiResponse<AssignedCompanyRepresentation>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling getAssignedCompanyKeys.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:read"]);
        }

        const response = await this.request({
            path: `/documents/assigned-companies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AssignedCompanyRepresentationFromJSON(jsonValue));
    }

    /**
     * Get assigned companies for the given document keys
     * Get assigned companies
     */
    async getAssignedCompanyKeys(requestBody: Array<string>): Promise<AssignedCompanyRepresentation> {
        const response = await this.getAssignedCompanyKeysRaw({ requestBody: requestBody });
        return await response.value();
    }

    /**
     * Get a documents accessible for the current user
     * Get a document
     */
    async getDocumentRaw(requestParameters: GetDocumentRequest): Promise<runtime.ApiResponse<DocumentRepresentation>> {
        if (requestParameters.docKey === null || requestParameters.docKey === undefined) {
            throw new runtime.RequiredError('docKey','Required parameter requestParameters.docKey was null or undefined when calling getDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:read"]);
        }

        const response = await this.request({
            path: `/documents/by-id/{docKey}`.replace(`{${"docKey"}}`, encodeURIComponent(String(requestParameters.docKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentRepresentationFromJSON(jsonValue));
    }

    /**
     * Get a documents accessible for the current user
     * Get a document
     */
    async getDocument(docKey: string): Promise<DocumentRepresentation> {
        const response = await this.getDocumentRaw({ docKey: docKey });
        return await response.value();
    }

    /**
     * Get a distinct list of all document categories
     * Get all document categories
     */
    async getDocumentCategoriesRaw(): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:read"]);
        }

        const response = await this.request({
            path: `/documents/categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get a distinct list of all document categories
     * Get all document categories
     */
    async getDocumentCategories(): Promise<Array<string>> {
        const response = await this.getDocumentCategoriesRaw();
        return await response.value();
    }

    /**
     * Get all documents accessible for the current user
     * Get all documents
     */
    async getDocumentsRaw(requestParameters: GetDocumentsRequest): Promise<runtime.ApiResponse<Array<DocumentRepresentation>>> {
        const queryParameters: any = {};

        if (requestParameters.chunkSize !== undefined) {
            queryParameters['chunk_size'] = requestParameters.chunkSize;
        }

        if (requestParameters.chunkOffset !== undefined) {
            queryParameters['chunk_offset'] = requestParameters.chunkOffset;
        }

        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.until !== undefined) {
            queryParameters['until'] = requestParameters.until;
        }

        if (requestParameters.allGenerators) {
            queryParameters['all_generators'] = requestParameters.allGenerators.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.anyGenerators) {
            queryParameters['any_generators'] = requestParameters.anyGenerators.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.allTags) {
            queryParameters['all_tags'] = requestParameters.allTags.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.category !== undefined) {
            queryParameters['category'] = requestParameters.category;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:read"]);
        }

        const response = await this.request({
            path: `/documents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DocumentRepresentationFromJSON));
    }

    /**
     * Get all documents accessible for the current user
     * Get all documents
     */
    async getDocuments(chunkSize?: number, chunkOffset?: number, text?: string, from?: number, until?: number, allGenerators?: Array<string>, anyGenerators?: Array<string>, allTags?: Array<string>, category?: string): Promise<Array<DocumentRepresentation>> {
        const response = await this.getDocumentsRaw({ chunkSize: chunkSize, chunkOffset: chunkOffset, text: text, from: from, until: until, allGenerators: allGenerators, anyGenerators: anyGenerators, allTags: allTags, category: category });
        return await response.value();
    }

    /**
     * Remove company assignment
     * Remove assignment
     */
    async removeCompanyRaw(requestParameters: RemoveCompanyRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.docKey === null || requestParameters.docKey === undefined) {
            throw new runtime.RequiredError('docKey','Required parameter requestParameters.docKey was null or undefined when calling removeCompany.');
        }

        if (requestParameters.companyKey === null || requestParameters.companyKey === undefined) {
            throw new runtime.RequiredError('companyKey','Required parameter requestParameters.companyKey was null or undefined when calling removeCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:read"]);
        }

        const response = await this.request({
            path: `/documents/by-id/{docKey}/assigned-companies/{companyKey}`.replace(`{${"docKey"}}`, encodeURIComponent(String(requestParameters.docKey))).replace(`{${"companyKey"}}`, encodeURIComponent(String(requestParameters.companyKey))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove company assignment
     * Remove assignment
     */
    async removeCompany(docKey: string, companyKey: string): Promise<void> {
        await this.removeCompanyRaw({ docKey: docKey, companyKey: companyKey });
    }

    /**
     * Retry document creation or download (depending on document source)
     * Retry
     */
    async retryRaw(requestParameters: RetryRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.docKey === null || requestParameters.docKey === undefined) {
            throw new runtime.RequiredError('docKey','Required parameter requestParameters.docKey was null or undefined when calling retry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["dashboards:read"]);
        }

        const response = await this.request({
            path: `/documents/by-id/{docKey}/retry`.replace(`{${"docKey"}}`, encodeURIComponent(String(requestParameters.docKey))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Retry document creation or download (depending on document source)
     * Retry
     */
    async retry(docKey: string): Promise<void> {
        await this.retryRaw({ docKey: docKey });
    }

}

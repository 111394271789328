/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard Service
 * This is the API documentation of the Dashboard service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssignedCompanyRepresentation
 */
export interface AssignedCompanyRepresentation {
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof AssignedCompanyRepresentation
     */
    documentKeysMap: { [key: string]: Array<string>; };
}

export function AssignedCompanyRepresentationFromJSON(json: any): AssignedCompanyRepresentation {
    return AssignedCompanyRepresentationFromJSONTyped(json, false);
}

export function AssignedCompanyRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignedCompanyRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'documentKeysMap': json['documentKeysMap'],
    };
}

export function AssignedCompanyRepresentationToJSON(value?: AssignedCompanyRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'documentKeysMap': value.documentKeysMap,
    };
}



import { i18n } from '@/plugins/i18n';

export enum NotificationType {
    ERROR = 'ERROR',
    WARN = 'WARN',
    INFO = 'INFO',
    SUCCESS = 'SUCCESS',
}

export interface Notification {
    title: string;
    text: string;
    type: NotificationType;
    shouldHideAutomatically: boolean;
}

export default class NotificationService {

    public static onNotificationAdded: (notification: Notification) => void;
    public static onNotificationRemoved: (notification: Notification) => void;

    public static success(title: string, text: string, shouldHideAutomatically = true) {
        this.showNotification(title, text, shouldHideAutomatically, NotificationType.SUCCESS);
    }

    public static info(title: string, text: string, shouldHideAutomatically = true) {
        this.showNotification(title, text, shouldHideAutomatically, NotificationType.INFO);
    }

    public static warn(title: string, text: string, shouldHideAutomatically = true) {
        this.showNotification(title, text, shouldHideAutomatically, NotificationType.WARN);
    }

    public static error(title: string, error: string|any, shouldHideAutomatically = false) {
        console.error(error);
        if (error && error.status) {
            this.showNotification(error.status, title, shouldHideAutomatically, NotificationType.ERROR);
        } else if (error) {
            this.showNotification(title, error, shouldHideAutomatically, NotificationType.ERROR);
        } else {
            this.showNotification('Unbekannter Fehler', title, shouldHideAutomatically, NotificationType.ERROR);
        }
    }

    public static serviceError(error: any, additionalInfo?: string, shouldHideAutomatically = false) {
        const key = `error.${error?.status}`;
        let title: string = i18n.t(key).toString();
        if (title === key) {
            title = i18n.t('error.undefined').toString();
        }
        if (additionalInfo) {
            title += ` - ${additionalInfo}`;
        }
        this.error(title, error, shouldHideAutomatically);
    }

    public static showNotification(title: string, text: string, shouldHideAutomatically: boolean, type: NotificationType = NotificationType.INFO) {
        const n: Notification = { title, text, type, shouldHideAutomatically };
        // setTimeout(() => this.hideNotification(n), 6000);
        this.onNotificationAdded(n);
    }

    public static hideNotification(n: Notification) {
        this.onNotificationRemoved(n);
    }
}
